import React, { FC, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router';

import { login, isAuthenticated } from '../utils/auth';
import { Footer } from '../components/Footer';

export const Login: FC = () => {
  const navigate = useNavigate();
  const [trying, setTrying] = React.useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    try {
      setTrying(true);
      const data = await login(email, password);
      if (data) {
        navigate('/dashboard');
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    } finally {
      setTrying(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Container component="main" maxWidth="xs" sx={{ padding: 0 }}>
        <Box
          sx={{
            padding: 2,
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src="/favicon.ico"/>
          <Typography component="h1" variant="h5">
            温度ロガーアプリ
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Eメールアドレス"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.currentTarget.value)
              }
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.currentTarget.value)
              }
              />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="ログインしたままにする"
            />*/}
            <LoadingButton
              type="submit"
              fullWidth
              loading={trying}
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
            >
              ログイン
            </LoadingButton>
            <Grid container sx={{ marginBottom: 2 }}>
              <Grid item xs>
              <Link href="#" variant="body2" onClick={() => navigate('/password-reminder')}>
                パスワードを忘れましたか?
                </Link>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
              {error && (
                <Grid item>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer/>
    </Box>
  );
};
