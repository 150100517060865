import React, { FC, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { isAdmin } from '../utils/auth';
import { Footer } from '../components/Footer';
import { Charts } from '../components/Charts';
import { Channels } from '../components/Channels';
import { Devices } from '../components/Devices';
import { ChartDialog } from '../components/ChartDialog';
import { DataList } from '../components/DataList';
import { ExportDialog } from '../components/ExportDialog';
import { EditUsers } from '../components/EditUsers';
import { EditDevices } from '../components/EditDevices';
import { EditGroups } from '../components/EditGroups';
import { EditChannels } from '../components/EditChannels';

const toGlobal = (date: Date) => {
  var offset = new Date().getTimezoneOffset();
  let copy = new Date(date.valueOf());
  copy.setMinutes(copy.getMinutes() + offset);
  return copy;
};

const toLocal = (date: Date) => {
  var offset = new Date().getTimezoneOffset();
  let copy = new Date(date.valueOf());
  copy.setMinutes(copy.getMinutes() - offset);
  return copy;
};

const UserNameDisplay = () => {
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const storedName = localStorage.getItem('full_name');
    if (storedName) {
      setFullName(storedName);
    }
  }, []);

  return (
    <Typography component="div" variant="body1" color="inherit">
      {fullName}
    </Typography>
  );
};

export const Dashboard: FC = () => {
  const [chartDialogOpen, setChartDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<string>('')
  const navigate = useNavigate();


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuText: string, path: string) => {
    setSelectedMenu(menuText); // 選択されたメニューのテキストを更新
    navigate(path);
    handleClose();
  };

  const handleClick = () => {
    //reload current route
    navigate(0);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2 }}>
            {selectedMenu}
          </Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem dense onClick={() => handleMenuItemClick('地点監視', 'channels')}>
              <ListItemIcon>
                <ListAltOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>地点監視</ListItemText>
            </MenuItem>
            <MenuItem dense onClick={() => handleMenuItemClick('端末管理', 'devices')}>
              <ListItemIcon>
                <ListAltOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>端末管理</ListItemText>
            </MenuItem>
            {/*
            グラフ表示は今後追加する可能性はある削除とする
            <MenuItem dense onClick={() => { handleClose(); setChartDialogOpen(true); }}>
              <ListItemIcon>
                <SsidChartOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>グラフ表示</ListItemText>
            </MenuItem>
            */}
            <MenuItem dense onClick={() => handleMenuItemClick('データ一覧表示', 'dataList')}>
              <ListItemIcon>
                <ListAltOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>データ一覧表示</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem dense onClick={() => { handleClose(); setExportDialogOpen(true); }}>
              <ListItemIcon>
                <CloudDownloadOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>エクスポート</ListItemText>
            </MenuItem>
            <Divider/>
            {isAdmin() && (
              <>
                <MenuItem dense onClick={() => handleMenuItemClick('ユーザー設定', 'edit_users')}>
                  <ListItemIcon>
                    <SettingsOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText>ユーザー設定</ListItemText>
                </MenuItem>
                <MenuItem dense onClick={() => handleMenuItemClick('端末設定', 'edit_devices')}>
                  <ListItemIcon>
                    <SettingsOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText>端末設定</ListItemText>
                </MenuItem>
                <MenuItem dense onClick={() => handleMenuItemClick('地点設定', 'edit_channels')}>
                  <ListItemIcon>
                    <SettingsOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText>地点設定</ListItemText>
                </MenuItem>
                <MenuItem dense onClick={() => handleMenuItemClick('グループ設定', 'edit_groups')}>
                  <ListItemIcon>
                    <SettingsOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText>グループ設定</ListItemText>
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem dense onClick={() => { navigate('/logout'); handleClose(); }}>
              <ListItemIcon>
                <LogoutOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>ログアウト</ListItemText>
            </MenuItem>
          </Menu>
          <ChartDialog
            open={chartDialogOpen}
            onClose={() => setChartDialogOpen(false)}
            onApply={(dateFrom: Date|null, dateTo: Date|null, isPreviousYear: boolean) => {
                setChartDialogOpen(false);
                let url = 'charts';
                url = `${url}?from=${toLocal(dateFrom ?? new Date()).toISOString().slice(0,10)}`;
                url = `${url}&to=${toLocal(dateTo ?? new Date()).toISOString().slice(0,10)}`;
                url = `${url}&isPreviousYear=${isPreviousYear}`;
                navigate(url);
              }}
            />
          {exportDialogOpen && (
            <ExportDialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)} />
          )}
          <Typography component="div" variant="h5" sx={{ flexGrow: 1 }}>
            
          </Typography>
          <UserNameDisplay />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
          </IconButton>
          <Button
            color="inherit"
            onClick={handleClick}
            variant="outlined"
            >
            <RefreshOutlinedIcon sx={{ml: -0.5}}/>
              更新
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Box>
          <Routes>
            <Route path="charts" element={<Charts/>}/>
            <Route path="channels" element={<Channels/>}/>
            <Route path="datalist" element={<DataList/>}/>
            <Route path="devices" element={<Devices/>}/>
            <Route path="edit_users" element={<EditUsers/>}/>
            <Route path="edit_devices" element={<EditDevices/>}/>
            <Route path="edit_channels" element={<EditChannels/>}/>
            <Route path="edit_groups" element={<EditGroups/>}/>
            <Route path="*" element={<Navigate to="channels" replace />}/>
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};
