import React, { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { BACKEND_URL } from '../config'
import { fetchWithAuth, fetchWithAuthMethod } from '../utils';


export const EditUsers: FC = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userGroupId, setUserGroupId] = useState('');
  const [groupOptions, setGroupOptions] = useState<{ label: string; value: string; }[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null); 
  const [emaiMessage, setEmailMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);
  
  async function findBindingByUserId(userId: string | null) {
    const response = await fetchWithAuth(`${BACKEND_URL}/user_group_bindings`).then(x => x.json());
    if (Array.isArray(response) && response.length > 0) {
      return response.find((binding) => binding.user_id === userId);
    } else {
      return null;
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailMessage('メールアドレスの形式が正しくありません。');
      return false;
    }
    return true;
  };

  const isValidPassword = (password: string) => {
    // 8文字以上16文字以下で英字と数字の組み合わせを要求する正規表現
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
    if (!passwordRegex.test(password)) {
      setPasswordMessage('パスワードは半角英字、数字を組み合わせた8～16文字で入力してください。');
      return false;
    }
    return true;
  };
  
  const fetchData = async () => {
    try {
      // ユーザー、ユーザーグループ、およびユーザーグループバインディングの情報を取得
      const [usersResponse, groupsResponse, bindingsResponse] = await Promise.all([
        fetchWithAuth(`${BACKEND_URL}/users`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/user_groups`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/user_group_bindings`).then(x => x.json())
      ]);

      // ユーザーのグループ情報をユーザーデータに結合してセット
      const usersWithGroups = usersResponse.map((user: any) => {
        // user_group_bindingsから該当するuser_idとgroup_idを見つける
        const binding = bindingsResponse.find((binding: any) => binding.user_id === user.id);
        if (binding) {
          // 該当するグループの情報を取得
          const group = groupsResponse.find((group: any) => group.id === binding.group_id);
          if (group) {
            // ユーザーのグループ情報を新しいオブジェクトとしてセット
            user.groupName = {
              label: group.name,
              value: group.id
            };
          }
        } else {
          // バインディングが見つからない場合は空文字列をセット
          user.groupName = {
            value: '',
            label: ''
          };
        }
        return user;
      });

      // ユーザーデータをセット
      setUsers(usersWithGroups);

      const groupOptionsData = Array.isArray(groupsResponse) ? groupsResponse : [groupsResponse];
      setGroupOptions(groupOptionsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = async (row: any) => {
    setEmailMessage('');
    setPasswordMessage('');
    setSelectedUserId(row.id); // ユーザーのIDを状態に保存
    setUserName(row.full_name); // ユーザーの名前をフォームにセット
    setUserEmail(row.email); // EMailをフォームにセット
    setUserPassword(row.hashed_password); // パスワードをフォームにセット
    // グループをフォームにセット
    const groupId = row.groupName ? row.groupName.value : ''; // row.groupName が存在する場合はその value を使用し、存在しない場合は空文字列をセットする
    setUserGroupId(groupId);
    setOpenEditDialog(true); // 編集ダイアログを開く
  };
  
  const handleEditUser = async () => {
    if (!selectedUserId) return; // ユーザーIDがない場合は処理しない

    // 確認メッセージを表示
    const confirmed = window.confirm('更新してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setEmailMessage('');
    setPasswordMessage('');

    if (!isValidEmail(userEmail)) {
      return;
    }
    
    if (!isValidPassword(userPassword)) {
      return;
    }

    setIsLoading(true); // ローディング開始

    // user_group_bindingを同時に更新する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/users/update_with_group_binding/${selectedUserId}?group_id=${userGroupId}`, `PUT`, {
      email: userEmail,
      is_active: true,
      is_superuser: false,
      full_name: userName,
      password: userPassword
    });

    if (response.ok) {
      // 更新が成功した場合はユーザーリストを更新
      const updatedUser = await response.json();

      // デバイスリスト更新
      setUsers(prevUsers => {
        const newUsers = [...prevUsers];
        const index = newUsers.findIndex(user => user.id === selectedUserId);    
        if (0 <= index) {
          // 指定デバイスのデータのみ更新
          newUsers[index] = {
            ...updatedUser
          };
          const group = groupOptions.find((group: any) => group.value === userGroupId);
          if (group) {
            newUsers[index].groupName = {
              label: group.label,
              value: group.value
            };
          }
        }
        return newUsers;
      });

      handleCloseDialog('edit'); // 編集ダイアログを閉じる
    } else {
      console.error('Failed to update user');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleDeleteClick = async (row: any) => {
    // 確認メッセージを表示
    const confirmed = window.confirm('削除してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setDeletingUserId(row.id); // ローディング開始

    // user_group_bindingsからselectedUserIdを見つける
    const binding = await findBindingByUserId(row.id);
    if (binding) {
      // user_group_bindingsの削除
      const bindingResponse = await fetchWithAuthMethod(`${BACKEND_URL}/user_group_bindings/${binding.id}`, `DELETE`);

      if (bindingResponse.ok) {
        console.log('Successfully delete user and bindings');
      } else {
        console.error('Failed to delete user bindings');
      }
    } else {
      console.error('User binding not found.');
    }

    // ユーザー削除
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/users/${row.id}`, `DELETE`);
    if (response.ok) {
      // ユーザーリスト更新
      setUsers(users.filter((user: any) => user.id !== row.id));
    } else {
      console.error('Failed to delete user');
    }

    setDeletingUserId(null); // ローディング終了
  };
  
  const handleAddClick = () => {
    setEmailMessage('');
    setPasswordMessage('');
    const defaultGroupId = groupOptions.length > 0 ? groupOptions[0].value : '';
    setUserGroupId(defaultGroupId);  // setUserGroupId を使用して userGroupId の値を更新
    setOpenAddDialog(true);
  };

  const handleAddUser = async () => {
    // 確認メッセージを表示
    const confirmed = window.confirm('追加してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setEmailMessage('');
    setPasswordMessage('');

    if (!isValidEmail(userEmail)) {
      return;
    }
    
    if (!isValidPassword(userPassword)) {
      return;
    }

    setIsLoading(true); // ローディング開始

    // ユーザーの追加
    // user_group_bindingを同時に追加する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/users/create_with_group_binding?group_id=${userGroupId}`, `POST`, {
      email: userEmail,
      is_active: true,
      is_superuser: false,
      full_name: userName,
      password: userPassword
    });

    if (response.ok) {
      // 新しく作成されたユーザーを取得
      const newUser = await response.json();
      // ユーザーリスト更新
      setUsers(prevUsers => {
        // 新しいユーザーの情報を準備
        const addDevice = {
          ...newUser
        };
      
        // ユーザーグループの情報を検索して追加
        const group = groupOptions.find((group: any) => group.value === userGroupId);
        if (group) {
          addDevice.groupName = {
            label: group.label,
            value: group.value
          };
        }
      
        // 既存のユーザーリストに新しいユーザーを追加
        return [...prevUsers, addDevice];
      });

      handleCloseDialog('add'); // 追加ダイアログを閉じる
    } else {
      console.error('Failed to add user');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleCloseDialog = (dialogType: 'add' | 'edit', event?: React.SyntheticEvent<Element, Event> | {}, reason?: string) => {
    // 背景をクリックされるとダイアログの各値がクリアされてしまう対策
    if (reason && reason === 'backdropClick') {
      return;
    }
    
    setSelectedUserId(null); // 編集が終了したので選択したユーザーIDをクリア
    setUserName(''); // フォームの値をクリア
    setUserEmail('');
    setUserPassword('');
    setUserGroupId('');

    if (dialogType === 'add') {
      setOpenAddDialog(false); // 追加ダイアログを閉じる
    } else if (dialogType === 'edit') {
      setOpenEditDialog(false); // 編集ダイアログを閉じる
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'ユーザー名称',
      editable: false,
      width: 300,
    },
    {
      field: 'email',
      headerName: 'EMail',
      editable: false,
      width: 300,
    },
    {
      field: 'groupName',
      headerName: 'ユーザーグループ',
      editable: false,
      width: 200,
	  valueGetter: (params) => params.row.groupName ? params.row.groupName.label : ''
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: '削除',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
          {deletingUserId === params.row.id ? (
            <CircularProgress size={24} />
          ) : (
            <DeleteIcon />
          )}
        </IconButton>
      ),
    }
  ];

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)' }} id="datalist-grid">
        <DataGrid
          rows={users}
          columns={columns}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          density="compact"
          hideFooter
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        />
      </Box>
      <IconButton aria-label="add" onClick={handleAddClick}>
        <AddIcon />
        <Typography variant="body1">追加</Typography>
      </IconButton>
      <Dialog open={openAddDialog} onClose={(event, reason) => handleCloseDialog('add', event || {}, reason)}>
        <DialogTitle>ユーザーの追加</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="ユーザー名称"
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="EMail"
            fullWidth
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <Typography variant="body2" color="error" dangerouslySetInnerHTML={{ __html: emaiMessage }} />
          <TextField
            autoFocus
            margin="dense"
            label="パスワード"
            fullWidth
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />
          <Typography variant="body2" color="error" dangerouslySetInnerHTML={{ __html: passwordMessage }} />
          <TextField
            select
            margin="dense"
            label="ユーザーグループ"
            fullWidth
            value={userGroupId} // ユーザーのグループを選択状態にする
            onChange={(e) => setUserGroupId(e.target.value)}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('add')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleAddUser}
              autoFocus
              disabled={(!userName || 0 >= userName.length) || !userEmail || 0 >= userEmail.length || !userPassword || 0 >= userPassword.length}
            >
              追加
            </Button>  
          )}          
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={(event, reason) => handleCloseDialog('edit', event || {}, reason)}>
        <DialogTitle>ユーザーの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="ユーザー名称"
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="EMail"
            fullWidth
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <Typography variant="body2" color="error" dangerouslySetInnerHTML={{ __html: emaiMessage }} />
          <TextField
            autoFocus
            margin="dense"
            label="パスワード"
            fullWidth
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />
          <Typography variant="body2" color="error" dangerouslySetInnerHTML={{ __html: passwordMessage }} />
          <TextField
            select
            margin="dense"
            label="ユーザーグループ"
            fullWidth
            value={userGroupId} // ユーザーのグループを選択状態にする
            onChange={(e) => setUserGroupId(e.target.value)}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('edit')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleEditUser}
              autoFocus
              disabled={(!userName || 0 >= userName.length) || !userEmail || 0 >= userEmail.length || !userPassword || 0 >= userPassword.length}
            >
              更新
            </Button>   
          )} 
        </DialogActions>
      </Dialog>
    </Box>
  );
}