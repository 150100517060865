import { FC, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';


interface Props {
  open: boolean
  onClose: any
  onApply: any
};

export const ChartDialog: FC<Props> = ({open, onClose, onApply}) => {
  const [dateFrom, setDateFrom] = useState<Date|null>(null);
  const [dateTo, setDateTo] = useState<Date|null>(null);
  const [displayDisabled, setDisplayDisabled] = useState(true);
  const [isPreviousYear, setIsPreviousYear] = useState(false);
  
  const handleChangeFrom = (newValue: Date | null) => {
    setDateFrom(newValue);
    setDisplayDisabled(!dateTo || !newValue || dateTo < newValue);
  };

  const handleChangeTo = (newValue: Date | null) => {
    setDateTo(newValue);
    setDisplayDisabled(!newValue || !dateFrom || newValue < dateFrom);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPreviousYear(event.target.checked);
  };

  const handleDisplay = () => {
    onApply(dateFrom, dateTo, isPreviousYear);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open}>
        <DialogTitle>グラフ表示</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{mt: 2, mb: 0}}>
            <MobileDatePicker
              label="開始日"
              inputFormat="yyyy/MM/dd"
              value={dateFrom}
              onChange={handleChangeFrom}
              renderInput={(params: any) => <TextField {...params} />}
            />
            <MobileDatePicker
              label="終了日"
              inputFormat="yyyy/MM/dd"
              value={dateTo}
              onChange={handleChangeTo}
              renderInput={(params: any) => <TextField {...params} />}
            />
            <FormControlLabel
              control={<Checkbox checked={isPreviousYear} onChange={handleCheckboxChange} />}
              label="前年同日のデータを表示する"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleDisplay} disabled={displayDisabled}>表示</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
