export * from './auth';

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

  export const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());


const makeAuthHeaders = () => new Headers({
    'Authorization': `Bearer ${localStorage.getItem('token')}` 
});

export const fetchWithAuth = (input: RequestInfo) => {
    return fetch(input, { headers: makeAuthHeaders() });
};

export const fetchWithAuthMethod = (input: RequestInfo, method?: string, body?: any) => {
    const authHeaders = makeAuthHeaders();
    const authHeaderValue = authHeaders.get('Authorization');
    console.log('Authorization Header:', authHeaderValue);

    const init: RequestInit = {
        headers: {}
    };

    if (authHeaderValue) {
        init.headers = {
            ...init.headers,
            'Authorization': authHeaderValue
        };
    }

    if (method) {
        init.method = method;
        console.log('Method:', method); 
    }

    if (body) {
        init.headers = {
            ...init.headers,
            'Content-Type': 'application/json'
        };
        init.body = JSON.stringify(body);
        console.log('Headers:', init.headers); 
        console.log('Body:', body); 
    }

    return fetch(input, init);
};


