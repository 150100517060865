import { FC, useState, useEffect, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { BACKEND_URL } from '../config';
import { fetchWithAuth, fetchWithAuthMethod } from '../utils';

export const EditChannels: FC = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [channelGroupId, setChannelGroupId] = useState('');
  const [channelDescription, setChannelDescription] = useState('');
  const [deviceChannelId, setDeviceChannelId] = useState('');
  const [selectedDeviceChannelId, setSelectedDeviceChannelId] = useState('');
  const [groupOptions, setGroupOptions] = useState<{ label: string; value: string; }[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null); 
  const [devices, setDevices] = useState<any[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [deviceGroupId, setDeviceGroupId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);

  const findBindingByChannelId = async (channelId: string) => {
    try {
      const bindingsResponse = await fetchWithAuth(`${BACKEND_URL}/channel_group_bindings`);
      if (!bindingsResponse.ok) {
        console.error('Failed to fetch channel bindings');
        return null;
      }
      const bindings = await bindingsResponse.json();
      return bindings.find((binding: any) => binding.channel_id === channelId);
    } catch (error) {
      console.error('Error fetching channel bindings:', error);
      return null;
    }
  };

  const fetchData = async (deviceId: string | null = null) => {
    try {
      const [channelsResponse, bindingsResponse] = await Promise.all([
        fetchWithAuth(`${BACKEND_URL}/channels`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/channel_group_bindings`).then(x => x.json())
      ]);
      // 選択されたデバイスIDに基づいてチャネルをフィルタリング
      const filteredChannels = deviceId
        ? channelsResponse.filter((channel: any) => channel.device_id === deviceId)
        : channelsResponse;    

      // チャネルのグループ情報をチャネルデータに結合してセット
      const channelsWithGroups = filteredChannels.map((channel: any) => {
        // channel_group_bindingsから該当するchannel_idとgroup_idを見つける
        const binding = bindingsResponse.find((binding: any) => binding.channel_id === channel.id);
        if (binding) {
          // 該当するグループの情報を取得
          const group = groupOptions.find((group: any) => group.value === binding.group_id);
          if (group) {
            // チャネルのグループ情報を新しいオブジェクトとしてセット
            channel.groupName = {
              label: group.label,
              value: group.value
            };
          }
        } else {
          // バインディングが見つからない場合は空文字列をセット
          channel.groupName = {
            label: '',
            value: ''
          };
        }
        return channel;
      });

      // チャネルデータをセット
      setChannels(channelsWithGroups);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchGroupOptions = async () => {
    try {
      // チャネルグループの選択肢を取得
      const response = await fetchWithAuth(`${BACKEND_URL}/channel_groups`);
      if (!response.ok) {
        throw new Error('Failed to fetch group options');
      }

      const responseData = await response.json();
      const groupOptionsData = Array.isArray(responseData) ? responseData : [responseData];

      setGroupOptions(groupOptionsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      })));
    } catch (error) {
      console.error('Error fetching group options:', error);
    }
  };

  const fetchDevices = async () => {
    try {
      // デバイス、デバイスグループ、およびデバイスグループバインディングの情報を取得
      const [devicesResponse, groupsResponse, bindingsResponse] = await Promise.all([
        fetchWithAuth(`${BACKEND_URL}/devices`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/device_groups`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/device_group_bindings`).then(x => x.json())
      ]);

      // デバイスのグループ情報をデバイスデータに結合してセット
      const devicesWithGroups = devicesResponse.map((device: any) => {
        // device_group_bindingsから該当するdevice_idとgroup_idを見つける
        const binding = bindingsResponse.find((binding: any) => binding.device_id === device.id);
        if (binding) {
          // 該当するグループの情報を取得
          const group = groupsResponse.find((group: any) => group.id === binding.group_id);
          if (group) {
            // デバイスのグループ情報を新しいオブジェクトとしてセット
            device.groupName = {
              label: group.name,
              value: group.id
            };
          }
        } else {
          // バインディングが見つからない場合は空文字列をセット
          device.groupName = {
            value: '',
            label: ''
          };
        }
        return device;
      });

      // デバイスデータをセット
      setDevices(devicesWithGroups);

      // デフォルトのデバイスIDを設定
      if (devicesWithGroups.length > 0) {
        const defaultDeviceId = devicesWithGroups[0].id;
        setSelectedDeviceId(defaultDeviceId);
        fetchData(defaultDeviceId); // デフォルトのデバイスIDでデータを取得

        // 選択されたデバイスのグループIDを取得して状態にセット
        setDeviceGroupId(devicesWithGroups[0].groupName.value);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDevices();
    fetchGroupOptions();
  }, []);

  useEffect(() => {
    if (selectedDeviceId) {
      fetchData(selectedDeviceId);
    }
  }, [selectedDeviceId]);  

  const handleDeviceChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const deviceId = event.target.value as string;
    setSelectedDeviceId(deviceId);

    // 選択されたデバイスのグループIDを取得して状態にセット
    const selectedDevice = devices.find(device => device.id === deviceId);
    setDeviceGroupId(selectedDevice?.groupName?.value || '');

    await fetchData(deviceId);
  };

  const handleEditClick = (row: any) => {
    setSelectedChannelId(row.id);
    setChannelName(row.name);
    setChannelGroupId(row.groupName ? row.groupName.value : '');
    setChannelDescription(row.description);
    setSelectedDeviceChannelId(row.device_channel_id);
    setDeviceChannelId(row.device_channel_id);
    setOpenEditDialog(true); // 編集ダイアログを開く
  };

  const handleEditChannel = async () => {
    if (!selectedChannelId) return; // チャネルIDがない場合は処理しない

    const confirmed = window.confirm('更新してもよろしいですか？');
    if (!confirmed) return;

    if (deviceChannelId !== selectedDeviceChannelId){
      if (channels.some(channel => channel.device_channel_id === selectedDeviceChannelId)) {
        alert('登録済みのデバイスチャネル番号には変更できません');
        return;
      }
    }

    setIsLoading(true); // ローディング開始

    // channel_group_bindingを同時に更新する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/channels/update_with_group_binding/${selectedChannelId}?group_id=${channelGroupId}`, `PUT`, {
      name: channelName,
      description: channelDescription,
      device_id: selectedDeviceId,
      device_channel_id: selectedDeviceChannelId
    });

    if (response.ok) {
      // チャネル更新が成功した場合はチャネルリストを更新
      const updatedChannel = await response.json();
      // チャネルリスト更新
      setChannels(prevChannels => {
        const newChannels = [...prevChannels];
        const index = newChannels.findIndex(channel => channel.id === selectedChannelId);    
        if (0 <= index) {
          // 指定デバイスのデータのみ更新
          newChannels[index] = {
            ...updatedChannel
          };
          const group = groupOptions.find((group: any) => group.value === channelGroupId);
          if (group) {
            newChannels[index].groupName = {
              label: group.label,
              value: group.value
            };
          }
        }
        return newChannels;
      });

      handleCloseDialog('edit'); // 編集ダイアログを閉じる
    } else {
      console.error('Failed to update channel');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleAddClick = () => {
    setChannelGroupId(deviceGroupId);

    // デバイスチャネル番号を設定
    const usedDeviceChannelIds = channels.map(channel => channel.device_channel_id);
    const newDeviceChannelId = usedDeviceChannelIds.includes(1) ? (usedDeviceChannelIds.includes(2) ? '' : '2') : '1';   
    setSelectedDeviceChannelId(newDeviceChannelId);
    setDeviceChannelId(newDeviceChannelId);

    setOpenAddDialog(true);
  };

  const handleAddChannel = async () => {
    console.log('channels:', channels);
    console.log('deviceChannelId:', deviceChannelId);
    const isDuplicate = channels.some(channel => {
      console.log('Checking channel:', channel);
      return channel.device_channel_id === deviceChannelId;
    });

    // 確認メッセージを表示
    const confirmed = window.confirm('追加してもよろしいですか？');
    if (!confirmed) return;

    if (deviceChannelId !== selectedDeviceChannelId){
      if (channels.some(channel => channel.device_channel_id === selectedDeviceChannelId)) {
        alert('登録済みのデバイスチャネル番号は追加できません');
        return;
      }
    }

    setIsLoading(true); // ローディング開始

    // チャネルの追加
    // channel_group_bindingを同時に追加する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/channels/create_with_group_binding?group_id=${channelGroupId}`, `POST`, {
      name: channelName,
      description: channelDescription,
      device_id: selectedDeviceId,
      device_channel_id: selectedDeviceChannelId
    });

    if (response.ok) {
      // 新しく作成されたチャネルを取得
      const newChannel = await response.json();
      // チャネルリスト更新
      setChannels(prevChannels => {
        // 新しいチャネルの情報を準備
        const addChannel = {
          ...newChannel
        };
      
        // チャネルグループの情報を検索して追加
        const group = groupOptions.find((group: any) => group.value === channelGroupId);
        if (group) {
          addChannel.groupName = {
            label: group.label,
            value: group.value
          };
        }
      
        // 既存のチャネルリストに新しいチャネルを追加
        return [...prevChannels, addChannel];
      });

      handleCloseDialog('add'); // 追加ダイアログを閉じる
    } else {
      console.error('Failed to add channel');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleDeleteClick = async (row: any) => {
    // 確認メッセージを表示
    const confirmed = window.confirm('削除してもよろしいですか？');
    if (!confirmed) return;

    setDeletingUserId(row.id); // ローディング開始

    // channel_group_bindingsから削除対象のチャネルIDを見つける
    const binding = await findBindingByChannelId(row.id);
    if (binding) {
      // channel_group_bindingsの削除
      const bindingResponse = await fetchWithAuthMethod(`${BACKEND_URL}/channel_group_bindings/${binding.id}`, `DELETE`);
      if (!bindingResponse.ok) {
        console.error('Failed to delete channel bindings');
      }
    } else {
      console.error('Channel binding not found.');
    }  

    // チャネル削除
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/channels/${row.id}`, `DELETE`);
    if (response.ok) {
      // チャネルリスト更新
      setChannels(channels.filter((channel: any) => channel.id !== row.id));
    } else {
      console.error('Failed to delete channel');
    }

    setDeletingUserId(null); // ローディング終了
  };

  const handleCloseDialog = (dialogType: 'add' | 'edit', event?: React.SyntheticEvent<Element, Event> | {}, reason?: string) => {
    // 背景をクリックされるとダイアログの各値がクリアされてしまう対策
    if (reason && reason === 'backdropClick') {
      return;
    }

    setSelectedChannelId(null);
    setChannelName('');
    setChannelGroupId('');
    setDeviceChannelId('');
    setChannelDescription('');

    if (dialogType === 'add') {
      setOpenAddDialog(false);
    } else if (dialogType === 'edit') {
      setOpenEditDialog(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '地点名称',
      editable: false,
      width: 160,
    },
    {
      field: 'groupName',
      headerName: '地点グループ',
      editable: false,
      width: 160,
      valueGetter: (params) => params.row.groupName ? params.row.groupName.label : ''
    },
    {
      field: 'description',
      headerName: '説明',
      editable: false,
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'device_channel_id',
      headerName: '端末チャネル番号',
      headerAlign: 'left',
      align: 'center',
      editable: false,
      width: 200,
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: '削除',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
          {deletingUserId === params.row.id ? (
            <CircularProgress size={24} />
          ) : (
            <DeleteIcon />
          )}
        </IconButton>
      ),
    }
  ];

  return (
    <Box sx={{ height: '60vh', width: '100%', marginTop: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)', marginBottom: 2 }} id="datalist-grid">
        {/* デバイスのコンボボックス */}
        <TextField
          select
          margin="dense"
          label="端末"
          fullWidth
          value={selectedDeviceId}
          onChange={handleDeviceChange}
          InputLabelProps={{
            shrink: true, // ラベルを常に表示するためのプロパティ
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  width: 'auto', // ドロップダウンメニューの幅を自動調整
                  minWidth: '95%', // コンボボックスの幅に合わせる
                },
              },
            },
          }}
        >
          {devices.map((device) => (
            <MenuItem key={device.id} value={device.id}>
              {device.name}
            </MenuItem>
          ))}
        </TextField>

        {/* データグリッド */}
        <DataGrid
          rows={channels}
          columns={columns}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          density="compact"
          hideFooter
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        />
      </Box>

      {/* 追加ボタン */}
      <IconButton
        aria-label="add"
        onClick={handleAddClick}
        disabled={channels.length >= 2}
      >
        <AddIcon />
        <Typography variant="body1">追加</Typography>
      </IconButton>

      <Dialog open={openAddDialog} onClose={(event, reason) => handleCloseDialog('add', event || {}, reason)}>
        <DialogTitle>地点の追加</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="地点名称"
            fullWidth
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="地点グループ"
            fullWidth
            value={channelGroupId}
            onChange={(e) => setChannelGroupId(e.target.value)}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            label="説明"
            fullWidth
            value={channelDescription}
            onChange={(e) => setChannelDescription(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="端末チャネル番号"
            fullWidth
            value={selectedDeviceChannelId}
            onChange={(e) => setSelectedDeviceChannelId(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('add')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleAddChannel}
              autoFocus
              disabled={(!channelName || 0 >= channelName.length)}
            >
              追加
            </Button> 
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={(event, reason) => handleCloseDialog('edit', event || {}, reason)}>
        <DialogTitle>地点の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="地点名称"
            fullWidth
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="地点グループ"
            fullWidth
            value={channelGroupId}
            onChange={(e) => setChannelGroupId(e.target.value)}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            label="説明"
            fullWidth
            value={channelDescription}
            onChange={(e) => setChannelDescription(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="端末チャネル番号"
            fullWidth
            value={selectedDeviceChannelId}
            onChange={(e) => setSelectedDeviceChannelId(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('edit')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleEditChannel}
              autoFocus
              disabled={(!channelName || 0 >= channelName.length)}
            >
              更新
            </Button> 
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
