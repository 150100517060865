import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { BACKEND_URL } from '../config'


export const PasswordReset: FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [trying, setTrying] = useState<boolean>(false);
  
  const navigate = useNavigate();

  const handleReset = async () => {
    setMessage('');
    setErrorMessage('');
    if (event) {
      event.preventDefault(); // デフォルトのフォーム送信を抑制
    }
    if (password !== confirmPassword) {
      setErrorMessage('パスワードが一致しません。');
      return;
    }

    // 8文字以上16文字以下で英字と数字の組み合わせを要求する正規表現
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage('パスワードは半角英字、数字を組み合わせた8～16文字で入力してください。');
      return;
    }

    setTrying(true);
    try {
      const token = new URLSearchParams(window.location.search).get("token");
      const response = await axios.post(`${BACKEND_URL}/reset-password`, {
        token: token,
        new_password: password,
      }); // 新しいパスワードをバックエンドに送信
      setMessage('パスワードがリセットされました。<br/>ログイン画面に戻ってログインしてください。');
    } catch (err) {
      setErrorMessage('エラーが発生しました。');
    } finally {
      setTrying(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Container component="main" maxWidth="xs" sx={{ padding: 0 }}>
        <Box
          sx={{
            padding: 2,
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src="/favicon.ico"/>
          <Typography component="h2" variant="h5">
            パスワードリセット
          </Typography>
           <Box component="form" onSubmit={handleReset} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="新しいパスワード"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
              autoFocus
                    sx={{ width: '100%' }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="パスワード確認"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.currentTarget.value)}
              sx={{ width: '100%' }}
            />
			<Typography variant="body2" color="error" dangerouslySetInnerHTML={{ __html: errorMessage }} />
            <LoadingButton
              type="submit"
              fullWidth
              loading={trying}
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2, width: '100%' }}
            >
              送信
            </LoadingButton>
            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: message }} />
            <Box sx={{ mt: 2 }}>
              <RouterLink to="/login#top">
                ログイン画面に戻る
              </RouterLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};