import { FC } from 'react';
import { ReactComponent as Antenna0 } from "./antenna0.svg";
import { ReactComponent as Antenna1 } from "./antenna1.svg";
import { ReactComponent as Antenna2 } from "./antenna2.svg";
import { ReactComponent as Antenna3 } from "./antenna3.svg";
import { ReactComponent as Antenna4 } from "./antenna4.svg";
import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  level: number
}

export const AntennaIcon: FC<Props> = ({level}) => (
  <SvgIcon>
    {
    (level === 4) ? (<Antenna4/>) :
    (level === 3) ? (<Antenna3/>) :
    (level === 2) ? (<Antenna2/>) :
    (level === 1) ? (<Antenna1/>) :
    (<Antenna0/>)
    }
  </SvgIcon>
);
