import decodeJwt from 'jwt-decode';
import { BACKEND_URL } from '../config';

export const isAuthenticated = () => {
  const user_id = localStorage.getItem('user_id');
  console.log('user_id:', user_id);
  if (!user_id) {
    return false;
  }
  return true;
};

export const isAdmin = () => {
  const permissions = localStorage.getItem('permissions');
  console.log('Permissions:', permissions);
  if (permissions != 'admin') {
    return false;
  }
  return true;
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  console.log('loginRequest');

  const request = new Request(`${BACKEND_URL}/login/access-token`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  //if (response.status > 400 && response.status < 500) {
  if (response.status < 200 || response.status >= 300) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    console.log('loginOK');
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('user_id', decodedToken.sub);
    localStorage.setItem('permissions', decodedToken.permissions);
    if (!decodedToken.full_name || decodedToken.full_name.trim() === '' || decodedToken.full_name === 'None') {
      // decodedToken.full_name
      localStorage.setItem('full_name', email);
    } else {
      localStorage.setItem('full_name', decodedToken.full_name);
    }
  }

  return data;
};

/**
 * Sign up via backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }
  if (!(passwordConfirmation.length > 0)) {
    throw new Error('Password confirmation was not provided');
  }

  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${BACKEND_URL}/users`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  //if (response.status > 400 && response.status < 500) {
  if (response.status < 200 || response.status >= 300) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('user_id', decodedToken.sub);
    localStorage.setItem('permissions', decodedToken.permissions);
  }

  return data;
};

export const logout = () => {
  console.log('logout');
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('permissions');
  localStorage.removeItem('full_name');
};
