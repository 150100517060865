import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { BACKEND_URL } from '../config'


export const PasswordReminder: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [trying, setTrying] = useState<boolean>(false);
  
  const navigate = useNavigate();

  const handleReminder = async () => {
    if (event) {
      event.preventDefault(); // デフォルトのフォーム送信を抑制
    }
    setTrying(true);
    try {
      const response = await axios.post(`${BACKEND_URL}/password-recovery/${email}`); // バックエンドにメールアドレスを送信
      //setMessage(response.data.message); // バックエンドからのレスポンスを表示
      setMessage('パスワードリセット用のメールを送信しました。<br/>メールを確認してパスワードをリセットしてください。');
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.detail) {
        setMessage(`エラーが発生しました。<br/>詳細: ${err.response.data.detail}`);
      } else {
        setMessage('エラーが発生しました。');
      }
    } finally {
      setTrying(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Container component="main" maxWidth="xs" sx={{ padding: 0 }}>
        <Box
          sx={{
            padding: 2,
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src="/favicon.ico"/>
          <Typography component="h2" variant="h5">
            パスワードリマインダー
          </Typography>
           <Box component="form" onSubmit={handleReminder} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Eメールアドレス"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
              autoFocus
			        sx={{ width: '100%' }}
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={trying}
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2, width: '100%' }}
            >
              送信
            </LoadingButton>
            <Typography variant="body2" color={message.includes('エラー') ? 'error' : 'textSecondary'} dangerouslySetInnerHTML={{ __html: message }} />
            <Box sx={{ mt: 2 }}>
              <RouterLink to="/login#top">
                ログイン画面に戻る
              </RouterLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};