import { FC } from 'react';
import { ReactComponent as Battery0 } from "./battery0.svg";
import { ReactComponent as Battery20 } from "./battery20.svg";
import { ReactComponent as Battery40 } from "./battery40.svg";
import { ReactComponent as Battery60 } from "./battery60.svg";
import { ReactComponent as Battery80 } from "./battery80.svg";
import { ReactComponent as BatteryFull } from "./batteryFull.svg";
import { ReactComponent as BatteryUnknown } from "./batteryUnknown.svg";
import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  level: number
}

export const BatteryIcon: FC<Props> = ({level}) => (
  <SvgIcon fontSize="large">
    {
    (level === 5) ? (<BatteryFull/>) :
    (level === 4) ? (<Battery80/>) :
    (level === 3) ? (<Battery60/>) :
    (level === 2) ? (<Battery40/>) :
    (level === 1) ? (<Battery20/>) :
    (level === 0) ? (<Battery0/>) :
    (<BatteryUnknown/>)
    }
  </SvgIcon>
);