import { format } from 'date-fns';

export  const formatUnixTime = (x: Date) => {  
    if (!x || isNaN(x.getTime())) {
        return '';  // xがnullまたはundefinedの場合、空文字を返す
    }

    const now = new Date();
    const f = (x.getFullYear() === now.getFullYear()) ? 'MM/dd\u00A0\u00A0\u00A0\u00A0HH:mm' : 'yyyy/MM/dd\u00A0\u00A0\u00A0\u00A0HH:mm';
    return format(x, f);
};