import { FC, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Login } from './views/Login';
import { Dashboard } from './views/Dashboard';
import { PasswordReminder } from './views/PasswordReminder';
import { PasswordReset } from './views/PasswordReset';
import { logout, isAuthenticated } from './utils/auth';

const Logout : FC = () => {
  useEffect(() => {
    logout();
  }, []);
  return (<Navigate to="/login" />);
};

export default function App() {
  return (
    <Box>
      <Routes>
        <Route path="/dashboard/*" element={<Dashboard/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/logout" element={<Logout/>}/>
        <Route path="/password-reminder" element={<PasswordReminder/>}/>
        <Route path="/reset-password" element={<PasswordReset/>}/>
        <Route path="*" element={
          isAuthenticated() ?
          (<Navigate to="/dashboard" replace/>) :
          (<Navigate to="/login" replace/>)}/>
      </Routes>
    </Box>
  );
}
