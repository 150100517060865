import { FC, useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { format } from 'date-fns';
import { download } from 'json-to-csv-in-browser';

import { BACKEND_URL } from '../config'
import { fetchWithAuth } from '../utils';

interface Props {
  open: boolean
  onClose: any
};

const toLocal = (date: Date) => {
  var offset = new Date().getTimezoneOffset();
  let copy = new Date(date.valueOf());
  copy.setMinutes(copy.getMinutes() - offset);
  return copy;
};

export const ExportDialog: FC<Props> = ({open, onClose}) => {
  const [exporting, setExporting] = useState(false);
  const [devices, setDevices] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null);
  const [selectedChannelName, setSelectedChannelName] = useState<string | null>(null);
  const [selectedDeviceName, setSelectedDeviceName] = useState<string | null>(null);

  const setChannelInfoById = (channelId: string) => {
    setSelectedChannelId(channelId);

    // チャネルとデバイス情報を使用して名称を設定
    const channel = channels.find((channel: any) => channel.id === channelId);
    if (channel) {
      setSelectedChannelName(channel.name);
      const device = devices.find((device: any) => device.id === channel.device_id);
      setSelectedDeviceName(device ? device.name : '');
    } else {
      setSelectedChannelName('');
      setSelectedDeviceName('');
    }
  };

  const fetchChannels = async () => {
    // 初期化
    setSelectedChannelId('');
    setSelectedChannelName('');
    setSelectedDeviceName('');

    try {
      // デバイスとチャネル情報を取得
      const [devicesResponse, channelsResponse] = await Promise.all([
        fetchWithAuth(`${BACKEND_URL}/devices`).then((x) => x.json()),
        fetchWithAuth(`${BACKEND_URL}/channels`).then((x) => x.json()),
      ]);
      setDevices(devicesResponse);
      setChannels(channelsResponse);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    if (channels.length > 0) {
      setChannelInfoById(channels[0].id);
    }
  }, [channels, devices])

  const handleChannelChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const channelId = event.target.value as string;
    setChannelInfoById(channelId);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleExecute = () => {
    let buffer: any[] = [];

    const exportData = async () => {
      try{
        setExporting(true);
        
        while(true){
          let url = `${BACKEND_URL}/records?channel_id=${selectedChannelId}`;
          url = `${url}&skip=${buffer.length}`;
          const records = await fetchWithAuth(url).then(x => x.json());
          if (records.length === 0){
            break;
          }
          buffer.push(...records);
        };

        const header = "\ufeff計測日,計測時刻," + selectedChannelName; //BOMをつけるとエクセルで文字化けしなくなる
        let lines = [header];
        const csvRows = buffer.map((x: any) => {
          const timestamp = format(toLocal(new Date(x.timestamp)), 'yyyy/MM/dd,HH:mm:ss');
          const value = x.phy_value;
          return `${timestamp},${value}`;
        });
        lines.push(...csvRows);
        
        const csvContent = lines.join('\r\n');
        const suffix = format(new Date(), 'yyyyMMddHHmmss');
        download(`${selectedChannelName}_${selectedDeviceName}_${suffix}.csv`, csvContent);
      }finally{
        setExporting(false);
        onClose();
      }
    };

    exportData()
    .catch(console.error);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>CSVエクスポート</DialogTitle>
      <DialogContent>
      <TextField
        select
        margin="dense"
        label="地点名称"
        fullWidth
        value={selectedChannelId}
        onChange={handleChannelChange}
        InputLabelProps={{ shrink: true }}
      >
        {channels.map((channel: any) => (
          <MenuItem key={channel.id} value={channel.id}>
            {channel.name}
          </MenuItem>
        ))}
      </TextField>
        <Backdrop
          sx={{ color: '#ccc', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={exporting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={exporting}>キャンセル</Button>
        <Button onClick={handleExecute} disabled={exporting}>実行</Button>
      </DialogActions>
    </Dialog>
  );
}
